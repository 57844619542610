@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@font-face {
  font-family: 'Urbanist';
  src: url('../font/Urbanist.woff2') format('woff2'),
       url('../font/Urbanist.woff') format('woff');
}

.hide{
  display: none !important;
}

load {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 9999;
}

@keyframes box-loader { 
  0% { box-shadow: none } 
  50% { box-shadow: 0 0.1rem 0.23rem 0 rgb(0 0 0 / 20%), 0 0.1rem 0.47rem 0 rgb(0 0 0 / 10%) } 
  100% { box-shadow: none }
}

@keyframes color-loader-sb { 
  0% {background-color:#FFFFFF} 
  50% {background-color:#dedede} 
  100% {background-color:#FFFFFF} 
}

@keyframes color-loader-cont {
  0% {background-color:#F7F5F5} 
  50% {background-color:#dedede} 
  100% {background-color:#F7F5F5} 
} 

@keyframes color-loader-cont-rev {
  0% {background-color:#F7F5F5} 
  50% {background-color:#FFFFFF} 
  100% {background-color:#F7F5F5} 
} 

.load-skeleton {
  border-radius: 0.47rem;
  animation: color-loader-sb 2.8s ease-in-out infinite;
}

.load-skeleton-cont {
  border-radius: 0.47rem;
  animation: color-loader-cont 2.8s ease-in-out infinite;
}

.load-skeleton-cont-rev {
  border-radius: 0.47rem;
  animation: color-loader-cont-rev 2.8s ease-in-out infinite;
}

.load-sidebar {
  width: 13.125rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.41rem 0.47rem 1.41rem 0.47rem;
  animation: box-loader 2.8s ease-in-out infinite;
}

.load-profile {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  margin: 0 0 0.47rem 1.41rem;
}

.load-alias {
  width: 8.44rem;
  height: 1.04rem;
  margin: 0 0 0 1.41rem;
}

.load-greeting {
  width: 8.44rem;
  height: 1.22rem;
  margin: 0.47rem 0 2.34rem 1.41rem;
}

.load-collap {
  width: 12.2rem;
  height: 2.25rem;
  margin-top: 0.23rem;
}

.load-inner-collap {
  width: 9.75rem;
  height: 2.25rem;
  margin: 0.23rem 0 0 1.875rem;
}

.load-logout {
  display: none;
  position:absolute;
  bottom: 0.47rem;
  width: 12.2rem;
  height: 2.25rem;
}

.load-content {
  display: none;
  width: calc(100vw - 13.125rem);
  height: 100vh;
  padding: 1.875rem;
  margin-left: 13.125rem;
  background-color: #F7F5F5;
}

.load-title {
  width: 23.44rem;
  height: 2.25rem;
}

.load-list {
  border-radius: 0.95rem;
  width: calc(100vw - 16.88rem);
  max-height: calc(100vh - 6.09rem);
  margin-top: 0.95rem;
  animation: box-loader 2.8s ease-in-out infinite;
  background-color: transparent;
}

.load-item {
  height: 3.75rem;
  width: 100%;
  border-radius: 0;
}

.first-item {
  border-top-left-radius: 0.95rem;
  border-top-right-radius: 0.95rem;
}

.last-item {
  border-bottom-right-radius: 0.95rem;
  border-bottom-left-radius: 0.95rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist';
}

body {
  display: flex;
  height: 100vh;
  font-size: 0.75rem;
}

.global-access {
  background-color: #E9ECF1;
  width: 100vw;
  height: 100vh;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;  
  width: 100vw;
  height: 2.34rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1rem;
  font-size: 0.85rem;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1%;
  padding-top: 2.81rem;
  overflow-y: auto;
  display: block;
}