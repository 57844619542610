.slider-container {
  margin-bottom: 1.41rem;
}

.slider {
  display: flex;
  border: 0.1rem solid #304b92; /* Border color (deep blue) */
  border-radius: 0.95rem;
  overflow: hidden;
}

.toggle {
  flex: 1;
  text-align: center;
  padding: 0.47rem 0;
  cursor: pointer;
  font-weight: bold;
  opacity: 0.5;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0.8rem;
}

.toggle.selected {
  background-color: #304b92; /* Selected (blue) color */
  color: white; /* Font color when selected */
  opacity: 1.0;
}

.toggle:hover {
  background-color: #304b92; /* Selected (blue) color */
  color: white; /* Font color when selected */
}

#otp-input,
#pw1-input,
#pw2-input,
#verif-submit-btn,
#verif-verif-btn,
#verif-resend-str {
  display: none;
}

#verif-submit-btn {
  margin-top: 0.8rem;
}

#verif-resend-str {
  color: #007BFF;
  margin-top: 1.3rem;
  margin-left: 0.4rem;
  opacity: 0.6;
}

#verif-resend-str:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
}

#verif-resend-str:active {
  color: #0056b3; /* Darker blue when clicked */
}


.how-to-2fa {
  opacity: 0.5;
  display: flex;
  justify-content: center;
  margin-bottom: 0.47rem;
}

.how-to-2fa:hover {
  text-decoration: underline;
  cursor: pointer;
  transform: translateY(-0.1rem);
  transform: scale(1.05);
  opacity: 1.0;
}

.how-to-2fa img {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none;
  width: 0.95rem;
  height: 0.95rem;
  margin-right: 0.23rem;
}

#tfa-result {
  position: absolute;
  margin-left: 0.47rem;
  margin-top: -0.23rem;
  font-weight: 800;
}

#countdown-container {
  display: none;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 2.34rem;
  border: 0.1rem solid #304b92; /* Deep blue */
  border-radius: 0.47rem;
  background-color: #c8d6f5;
  box-shadow: 0 0.19rem 0.28rem rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

#countdown-string {
  font-weight: bold;
  color: white;
  letter-spacing: 0.05rem;
  animation: pulse 1s infinite; /* Pulse animation for attention */
  color: #565b6c;
}

#countdown-container.timeup {
  border: 0.1rem solid red !important;
  background-color: #ffcccc !important;
  justify-content: space-evenly;
}

#countdown-regen-otp {
  display: none;
  padding: 0.1rem 0.47rem;
  border-radius: 0.56rem;
  background-color: #000000;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 0.96rem;
  color: #FFFFFF;
}

#countdown-regen-otp:hover {
  background-color: #333333; /* Lighter black for hover */
  transform: scale(1.05); /* Slightly enlarge the button */
}

#mail-invalid-string, #otp-invalid-string, #password-invalid-string {
  display: none;
  color: orangered !important;
  top: 2.5rem;
  position: absolute;
  left: 0.5rem;
}

#pw1-toggle{
  top: 5.65rem;
}

#pw2-toggle {
  top: 9.5rem;
}

.visibility-icon.forgotpw {
  display: none;
}