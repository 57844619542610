.login-cont {
	width: 42.19rem;
	height: 19.69rem;
	position: absolute;
	top: 50%;
	left: 50%;
  transform: translate(-50%, -50%);
	padding: 1.875rem;
	border-radius: 1.125rem;
	background-color: #FFFFFF;
	display: flex;
}

.login-info-cont {
	width: 18.28rem;
	height: 15.94rem;
}

.login-info-cont img {
	height: 3.75rem;
  margin-bottom: 0.47rem;
}

.login-info-cont h2 {
	font-size: 1.31rem;
	font-weight: 600;
	line-height: 1.575rem;
	letter-spacing: -0.025ren;
	text-align: left;
	color: #000000;
	margin: 0.95rem 0 0.23rem 0;
}

.login-info-cont h5 {
	margin-bottom: 0.95rem;
}

.login-info-cont p {
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 0.96rem;
	text-align: left;
	color: #565B6C;
}

.login-input-cont {
	width: 18.28rem;
	height: 15.94rem;
	position: relative;
	display: grid;
	align-items: center;
}

.login-input-cont span {
	font-size: 0.70rem;
	font-weight: 500;
	line-height: 0.85rem;
	text-align: left;
}

.login-input-cont p {
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 0.96rem;
	text-align: left;
	color: #565B6C;
	margin-top: 0.95rem;
}

.login-input-cont button {
	width: 6.1rem;
	height: 2.25rem;
	padding: 0.23rem 0.75rem 0.23rem 0.75rem;
	border-radius: 0.56rem;
	background-color: #000000;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 0.96rem;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	right: 0;
}

.login-input-outer button {
	top: 4.69rem;
	right: 0;
}

.login-input-outer{
	position: relative;
}

#createUserModal_content .input-cont .input-field,
.login-input-field {
	position: relative;
}

#createUserModal_content .input-cont .input-field .form-control:not(:placeholder-shown) ~ img,
#createUserModal_content .input-cont .input-field .form-control:not(:placeholder-shown) ~ span,
#createUserModal_content .input-cont .input-field .form-control:focus ~ img,
#createUserModal_content .input-cont .input-field .form-control:focus ~ span,
.login-input-field .form-control:not(:placeholder-shown) ~ img,
.login-input-field .form-control:not(:placeholder-shown) ~ span,
.login-input-field .form-control:focus ~ img,
.login-input-field .form-control:focus ~ span {
  opacity: 0;
  transition: opacity 0.2s ease; /* Smooth transition for hiding */
}

#createUserModal_content .input-cont .input-field label,
.login-input-field label { 
	font-size: 0.56rem;
	font-weight: 500;
	line-height: 0.51rem;
	text-align: left;
	color: #000000;
	margin-left: 0.70rem;
}

#createUserModal_content .input-cont .input-field input,
.login-input-field input { 
	padding: 0.47rem 0.75rem 0.47rem 0.75rem;
	border-radius: 0.56rem;
	background-color: #E9ECF1;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 0.96rem;
	text-align: left;
	color: #6D7183;
	margin-bottom: 0.70rem;
}

#createUserModal_content .input-cont .input-field img,
.login-input-field img {
  position: absolute;
  top: 70%;
  left: 0.70rem;
  transform: translateY(-50%);
  pointer-events: none; 
  width: 1.125rem;
  height: 1.125rem;
  color: #007BFF;
}

#createUserModal_content .input-cont .input-field span,
.login-input-field span {
  position: absolute;
  top: 70%;
  left: 2.34rem;
  transform: translateY(-50%);
  pointer-events: none; 
  color: #6D7183;
}

.login-input-field .invalid {
	border: 1px solid orangered;
}

.visibility-icon {
	position: absolute;
	top: 9.8rem;
	left: auto;
	right: 0.70rem;
	color: #6D7183;
  opacity: 1 !important;
  cursor: pointer;
  z-index: 9999;
}

.login-input-outer .visibility-icon{
	top: 1.64rem;
}

#login-verify-btn,
#login-pw-cont,
#login-otp-cont {
	display: none;
}


#login-pw-cont p {
	margin-top: -0.47rem;
	margin-left: 0.70rem;
	font-size: 0.66rem;
	line-height: 0.66rem;
	opacity: 0.8;
	font-weight:600;
}

#login-pw-cont button {
	top: 9.375rem;
}

#forgot-password-btn {
	position: absolute;
	bottom: -1.2rem;
	right: 0.3rem;
	color: #007BFF;
}

#forgot-password-btn:hover {
	text-decoration: underline;
	cursor: pointer;
}

#cred-invalid-string, #email-invalid-string, #login-otp-invalid-string{
	display: none;
  color: orangered !important;
  position: absolute;
  left: 0.5rem;
}

#login-otp-invalid-string {
	display: block !important;
	bottom: 3.5rem;
}

#cred-invalid-string {
  bottom: 2rem;
}

#email-invalid-string	{
  bottom: 6.5rem;
}
